import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TimelineComponent } from "./component/timeline/timeline.component";
import { SeachMenuComponent } from "./component/seach-menu/seach-menu.component";
import { routingAuthGuard } from "./routing-auth/routing-auth.guard";



const routes: Routes = [
  // {
  //   path: "",
  //   loadComponent: () =>
  //     import(
  //       "./component/layout/authenticated-homepage/authenticated-homepage.component"
  //     ).then((component) => component.AuthenticatedHomepageComponent),
  // },
  // {
  //   path: "",
  //   loadComponent: () =>
  //     import(
  //       "./component/landing-home-page/landing-home-page.component"
  //     ).then((component) => component.LandingHomePageComponent),
  // },
  {
    path: "",
    loadComponent: () =>
      import(
        "./component/default-homepage/default-homepage.component"
      ).then((component) => component.DefaultHomepageComponent),
  },

  {
    path: "accounts/:id",
    loadComponent: () =>
      import("./component/account-details/account-details.component").then(
        (component) => component.AccountDetailsComponent
      ),
  },
  {
    path: "accounts",
    loadComponent: () =>
      import("./component/account-details/account-details.component").then(
        (component) => component.AccountDetailsComponent
      ),
  },
  {
    path: "crm/cases/:id",
    loadComponent: () =>
      import("./component/cases/cases.component").then(
        (component) => component.CasesComponent
      ),
  },
  // {
  //   path: "cases",
  //   loadComponent: () =>
  //     import("./component/cases/cases.component").then(
  //       (component) => component.CasesComponent
  //     ),
  // },
  {
    path: "crm/cases",
    loadComponent: () =>
      import("./component/cases-list/cases-list.component").then(
        (component) => component.CasesListComponent
      ),
  },
  {
    path: "dashboards/:id",
    loadComponent: () =>
      import("./component/dashboard-page/dashboard-page.component").then(
        (component) => component.DashboardPageComponent
      )
  },
  // {
  //   path: "dashboards",
  //   loadComponent: () =>
  //     import("./component/dashboard-page/dashboard-page.component").then(
  //       (component) => component.DashboardPageComponent
  //     )
  // },
  {
    path: "chart",
    loadComponent: () =>
      import("./component/chart-settings/chart-settings.component").then(
        (component) => component.ChartSettingsComponent
      ),
  },
  {
    path: "chart/:id",
    loadComponent: () =>
      import("./component/chart-settings/chart-settings.component").then(
        (component) => component.ChartSettingsComponent
      ),
  },
  {
    path: "tables/:tableName",
    loadComponent: () =>
      import(
        "./component/admin/table-description/table-description.component"
      ).then((component) => component.TableDescriptionComponent),
  },
  {
    path: "tables",
    loadComponent: () =>
      import("./component/admin/tables/tables.component").then(
        (component) => component.TablesComponent
      ),
  },
  {
    path: "tables/:tableId/columns/:columnId",
    loadComponent: () =>
      import(
        "./component/admin/column-description/column-description.component"
      ).then((component) => component.ColumnDescriptionComponent),
  },
  {
    path: "tables/:tableId/triggers/:id",
    loadComponent: () =>
      import("./component/triggers/triggers.component").then(
        (component) => component.TriggersComponent
      ),
  },
  {
    path: "random-data-generator",
    loadComponent: () =>
      import(
        "./component/random-data-generator/random-data-generator.component"
      ).then((component) => component.RandomDataGeneratorComponent),
  },
  {
    path: "jobs/:id",
    loadComponent: () =>
      import(
        "./component/admin/background-jobs/background-job-editor/background-job-editor.component"
      ).then((component) => component.BackgroundJobEditorComponent),
  },
  {
    path: "jobs",
    loadComponent: () =>
      import(
        "./component/admin/background-jobs/background-jobs.component"
      ).then((component) => component.BackgroundJobsComponent),
  },
  {
    path: "login",
    loadComponent: () =>
      import("./component/layout/login/login.component").then(
        (component) => component.LoginComponent
      ),
  },
  {
    path: "oauth2-login-code/:provider",
    loadComponent: () =>
      import("./component/user-services/oauth-callback/oauth-callback.component").then(
        (component) => component.OauthCallbackComponent
      ),
  },
  {
    path: "oauth2-login-code",
    loadComponent: () =>
      import("./component/user-services/oauth-callback/oauth-callback.component").then(
        (component) => component.OauthCallbackComponent
      ),
  },
  {
    path: "signup",
    loadComponent: () =>
      import("./component/layout/sign-up/sign-up.component").then(
        (component) => component.SignUpComponent
      ),
  },
  {
    path: "change-password",
    loadComponent: () =>
      import(
        "./component/user-services/change-password-no-auth/change-password-no-auth.component"
      ).then((component) => component.ChangePasswordNoAuthComponent),
  },
  {
    path: "reset-password/:id",

    loadComponent: () =>
      import(
        "./component/user-services/reset-password/reset-password.component"
      ).then((component) => component.ResetPasswordComponent),
  },
  {
    path: "verify-email",
    loadComponent: () =>
      import(
        "./component/user-services/process-email-verification/process-email-verification.component"
      ).then((component) => component.ProcessEmailVerificationComponent),
  },
  {
    path: "reports/:id",
    loadComponent: () =>
      import("./component/layout/search-viewer/search-viewer.component").then(
        (component) => component.SearchViewerComponent
      ),
  },

  {
    path: "reports",
    loadComponent: () =>
      import("./component/default-report/default-report.component").then(
        (component) => component.DefaultReportComponent
      ),
  },




  // {
  //   path: "tables/:tableName/search",
  //   loadComponent: () =>
  //     import("./component/layout/search/search.component").then(
  //       (component) => component.SearchComponent
  //     ),
  // },
  // {
  //   path: "tables/:tableName/query",
  //   loadComponent: () =>
  //     import(
  //       "./component/layout/search/search-wrapper/search-wrapper.component"
  //     ).then((component) => component.SearchWrapperComponent),
  // },
  {
    path: "query/:queryId",
    loadComponent: () =>
      import("./component/layout/search/search.component").then(
        (component) => component.SearchComponent
      ),
  },
  {
    path: "query",
    loadComponent: () =>
      import("./component/layout/search/search.component").then(
        (component) => component.SearchComponent
      ),
  },
  {
    path: "search-log/:queryId",
    loadComponent: () =>
      import("./component/search-log-viewer/search-log-viewer.component").then(
        (component) => component.SearchLogViewerComponent
      ),
  },

  {
    path: "search-summary/:name",
    loadComponent: () =>
      import("./component/search-summary/search-summary.component").then(
        (component) => component.SearchSummaryComponent
      ),
  },
  {
    path: "search-summary",
    loadComponent: () =>
      import("./component/search-summary/search-summary.component").then(
        (component) => component.SearchSummaryComponent
      ),
  },
  {
    path: "search-editor/:queryId",
    loadComponent: () =>
      import(
        "./component/layout/search/search-editor/search-editor.component"
      ).then((component) => component.SearchEditorComponent),
  },
  // {
  //   path: "search-builder",
  //   loadComponent: () =>
  //     import(
  //       "./component/layout/search/search-builder/search-builder.component"
  //     ).then((component) => component.SearchBuilderComponent),
  // },
  {
    path: "system",
    loadComponent: () =>
      import(
        "./component/background-services/background-services.component"
      ).then((component) => component.BackgroundServicesComponent),
  },
  {
    path: "users",
    loadComponent: () =>
      import("./component/users/user-list/user-list.component").then(
        (component) => component.UserListComponent
      ),
  },
  {
    path: "users/:id",
    loadComponent: () =>
      import("./component/users/users.component").then(
        (component) => component.UsersComponent
      ),
  },
  {
    path: "user-profile",
    loadComponent: () =>
      import("./component/users/users.component").then(
        (component) => component.UsersComponent
      ),
  },
  {
    path: "user-groups/:id",
    loadComponent: () =>
      import("./component/user-group/user-group.component").then(
        (component) => component.UserGroupComponent
      ),
  },
  {
    path: "user-groups",
    loadComponent: () =>
      import("./component/user-group/user-group.component").then(
        (component) => component.UserGroupComponent
      ),
  },
  {
    path: "schema-explorer",
    loadComponent: () =>
      import("./component/schema-explorer/schema-explorer.component").then(
        (component) => component.SchemaExplorerComponent
      ),
  },
  {
    path: "detail/:table/:id",
    loadComponent: () =>
      import(
        "./component/layout-composition/layout-composition.component"
      ).then((component) => component.LayoutCompositionComponent),
  },
  {
    path: "d/:table/:id",
    loadComponent: () =>
      import(
        "./component/layout-composition/layout-composition.component"
      ).then((component) => component.LayoutCompositionComponent),
  },
  {
    path: "layout/:layoutId/sections/:sectionId",
    loadComponent: () =>
      import(
        "./component/layout-composition/editor-layout-section-form/editor-layout-section-form.component"
      ).then((component) => component.EditorLayoutSectionFormComponent),
  },
  {
    path: "global-app-config",
    loadComponent: () =>
      import("./component/admin/app-config/app-config.component").then(
        (component) => component.AppConfigComponent
      ),
  },
  {
    path: "global-app-config/:id",
    loadComponent: () =>
      import("./component/admin/app-config/app-config.component").then(
        (component) => component.AppConfigComponent
      ),
  },
  {
    path: "permission-set/user/:id",
    loadComponent: () =>
      import(
        "./component/admin/permission-set/permission-sets-by-user/permission-sets-by-user.component"
      ).then((component) => component.PermissionSetsByUserComponent),
  },
  {
    path: "permission-set/assignments/:id",
    loadComponent: () =>
      import(
        "./component/admin/permission-set/permission-set-assignment/permission-set-assignment.component"
      ).then((component) => component.PermissionSetAssignmentComponent),
  },
  {
    path: "permission-set/:id",
    loadComponent: () =>
      import("./component/admin/permission-set/permission-set.component").then(
        (component) => component.PermissionSetComponent
      ),
  },
  {
    path: "permission-set",
    loadComponent: () =>
      import("./component/admin/permission-set/permission-set.component").then(
        (component) => component.PermissionSetComponent
      ),
  },
  // {
  //   path: "process-groups/:id",
  //   loadComponent: () =>
  //     import("./component/process-groups/process-groups.component").then(
  //       (component) => component.ProcessGroupsComponent
  //     ),
  // },
  // {
  //   path: "process-groups",
  //   loadComponent: () =>
  //     import("./component/process-groups/process-groups.component").then(
  //       (component) => component.ProcessGroupsComponent
  //     ),
  // },
  {
    path: "file-explorer",
    loadComponent: () =>
      import("./component/file-explorer/file-explorer.component").then(
        (component) => component.FileExplorerComponent
      ),
  },
  {
    path: "file",
    loadComponent: () =>
      import("./component/file/file.component").then(
        (component) => component.FileComponent
      ),
  },
  {
    path: "global-lists/:id",
    loadComponent: () =>
      import("./component/global-list/global-list.component").then(
        (component) => component.GlobalListComponent
      ),
  },
  {
    path: "global-lists",
    loadComponent: () =>
      import("./component/global-list/global-list.component").then(
        (component) => component.GlobalListComponent
      ),
  },
  {
    path: "remote-agent/tasks/:taskId",
    loadComponent: () =>
      import("./component/remote-agent-task/remote-agent-task.component").then(
        (component) => component.RemoteAgentTaskComponent
      ),
  },
  {
    path: "remote-agents/:agentId",
    loadComponent: () =>
      import(
        "./component/remote-ingester-config/remote-ingester-config.component"
      ).then((component) => component.RemoteIngesterConfigComponent),
  },
  // {
  //   path: "remote-agents",
  //   loadComponent: () =>
  //     import(
  //       "./component/remote-ingester-config/remote-ingester-config.component"
  //     ).then((component) => component.RemoteIngesterConfigComponent),
  // },
  {
    path: "remote-agents",
    loadComponent: () =>
      import(
        "./component/agent-list/agent-list.component"
      ).then((component) => component.AgentListComponent),
  },

  {
    path: "parsers/:parserId",
    loadComponent: () =>
      import("./component/parser-details/parser-details.component").then(
        (component) => component.ParserDetailsComponent
      ),
  },
  // {
  //   path: "feature-extraction",
  //   loadComponent: () =>
  //     import("./component/parser-details/parser-details.component").then(
  //       (component) => component.ParserDetailsComponent
  //     ),
  // },
  {
    path: "parsers",

    loadComponent: () =>
      import("./component/parser-list/parser-list.component").then(
        (component) => component.ParserListComponent
      ),
  },
  {
    path: "playbooks",
    loadComponent: () =>
      import("./component/playbook-list/playbook-list.component").then(
        (component) => component.PlaybookListComponent
      ),
  },
  {
    path: "playbooks/:id",
    loadComponent: () =>
      import("./component/script-editor/script-editor.component").then(
        (component) => component.ScriptEditorComponent
      ),
  },
  {
    path: "setup",
    loadComponent: () =>
      import("./component/setup/setup.component").then(
        (component) => component.SetupComponent
      ),
  },
  {
    path: "error/:mode",
    loadComponent: () =>
      import("./component/bad-request/bad-request.component").then(
        (component) => component.BadRequestComponent
      ),
  },
  // {
  //   path: "topics/:id",
  //   loadComponent: () =>
  //     import("./component/admin/kafka/kafka.component").then(
  //       (component) => component.KafkaComponent
  //     ),
  // },
  // {
  //   path: "topics",
  //   loadComponent: () =>
  //     import("./component/admin/kafka/kafka.component").then(
  //       (component) => component.KafkaComponent
  //     ),
  // },
  // {
  //   path: "topics-list",
  //   loadComponent: () =>
  //     import("./component/topics-list/topics-list.component").then(
  //       (component) => component.TopicsListComponent
  //     ),
  // },


  {
    path: "email-templates/:id",
    loadComponent: () =>
      import("./component/email-template/email-template.component").then(
        (component) => component.EmailTemplateComponent
      ),
  },
  // {
  //   path: "email-template",
  //   loadComponent: () =>
  //     import("./component/email-template/email-template.component").then(
  //       (component) => component.EmailTemplateComponent
  //     ),
  // },
  {
    path: "email-templates",
    loadComponent: () =>
      import("./component/email-template-list/email-template-list.component").then(
        (component) => component.EmailTemplateListComponent
      ),
  },

  {
    path: "home",
    loadComponent: () =>
      import(
        "./component/layout/unauthenticated-homepage/unauthenticated-homepage.component"
      ).then((component) => component.UnauthenticatedHomepageComponent),
  },
  {
    path: "rules",
    loadComponent: () =>
      import("./component/rule-home-page/rule-home-page.component").then(
        (component) => component.RuleHomePageComponent
      ),
  },
  {
    path: "rules/:id",
    loadComponent: () =>
      import("./component/rule-details/rule-details.component").then(
        (component) => component.RuleDetailsComponent
      ),
  },
  {
    path: "apps",
    loadComponent: () =>
      import("./component/apps/apps.component").then(
        (component) => component.AppsComponent
      ),
  },
  {
    path: "apps/:id",
    loadComponent: () =>
      import("./component/apps/apps.component").then(
        (component) => component.AppsComponent
      ),
  },
  // {
  //   path: "pipelines/:id",
  //   loadComponent: () =>
  //     import("./component/pipeline/pipeline.component").then(
  //       (component) => component.PipelineComponent
  //     ),
  // },
  // {
  //   path: "pipelines",
  //   loadComponent: () =>
  //     import("./component/pipeline/pipeline.component").then(
  //       (component) => component.PipelineComponent
  //     ),
  // },
  {
    path: "service-status/:mode",
    loadComponent: () =>
      import(
        "./component/background-services/background-services.component"
      ).then((component) => component.BackgroundServicesComponent),
  },
  {
    path: "service-status",
    loadComponent: () =>
      import(
        "./component/background-services/background-services.component"
      ).then((component) => component.BackgroundServicesComponent),
  },
  {
    path: "entity-extracts",
    loadComponent: () =>
      import("./component/entity-extracts/entity-extracts.component").then(
        (component) => component.EntityExtractsComponent
      ),
  },
  {
    path: "entity-extracts-settings",
    loadComponent: () =>
      import(
        "./component/entity-extracts-settings/entity-extracts-settings.component"
      ).then((component) => component.EntityExtractsSettingsComponent),
  },
  {
    path: "entity-extracts-settings/:id",
    loadComponent: () =>
      import(
        "./component/entity-extracts-settings/entity-extracts-settings.component"
      ).then((component) => component.EntityExtractsSettingsComponent),
  },
  {
    path: "contact-us",
    loadComponent: () =>
      import("./component/contact-us/contact-us.component").then(
        (component) => component.ContactUsComponent
      ),
  },
  {
    path: "crm/leads/:id",
    loadComponent: () =>
      import("./component/lead-management/lead-management.component").then(
        (component) => component.LeadManagementComponent
      ),
  },
  // {
  //   path: "leads",
  //   loadComponent: () =>
  //     import("./component/lead-management/lead-management.component").then(
  //       (component) => component.LeadManagementComponent
  //     ),
  // },
  {
    path: "crm/leads",
    loadComponent: () =>
      import("./component/leads-list/leads-list.component").then(
        (component) => component.LeadsListComponent
      ),
  },
  // {
  //   path: "leads-list",
  //   loadComponent: () =>
  //     import("./component/leads-list/leads-list.component").then(
  //       (component) => component.LeadsListComponent
  //     ),
  // },


  {
    path: "cfs/:cf",
    loadComponent: () =>
      import("./component/cfs/cfs.component").then(
        (component) => component.CfsComponent
      ),
  },
  {
    path: "cfs",
    loadComponent: () =>
      import("./component/cfs/cfs.component").then(
        (component) => component.CfsComponent
      ),
  },
  {
    path: "firewall-app",
    loadComponent: () =>
      import("./component/firewall-app/firewall-app.component").then(
        (component) => component.FirewallAppComponent
      ),
  },
  {
    path: "time-line",
    component: TimelineComponent,
  },
  {
    path: "search-menu",
    component: SeachMenuComponent,
  },
  {
    path: "threads/:id",
    loadComponent: () =>
      import("./component/qna/qna.component").then(
        (component) => component.QnaComponent
      ),
  },
  {
    path: "threads",
    loadComponent: () =>
      import("./component/qna/qna.component").then(
        (component) => component.QnaComponent
      ),
  },
  // {
  //   path: "webhooks/:id",
  //   loadComponent: () =>
  //     import("./component/webhooks/webhooks.component").then(
  //       (component) => component.WebhooksComponent
  //     ),
  // },
  // {
  //   path: "webhooks",
  //   loadComponent: () =>
  //     import("./component/webhooks/webhooks.component").then(
  //       (component) => component.WebhooksComponent
  //     ),
  // },
  {
    path: "webhooks",
    loadComponent: () =>
      import("./component/webhook-list/webhook-list.component").then(
        (component) => component.WebhookListComponent
      ),
  },
  {
    path: "webtrackings/:id",
    loadComponent: () =>
      import("./component/webtracking/webtracking.component").then(
        (component) => component.WebtrackingComponent
      ),
  },
  // {
  //   path: "webtrackings",
  //   loadComponent: () =>
  //     import("./component/webtracking/webtracking.component").then(
  //       (component) => component.WebtrackingComponent
  //     ),
  // },
  {
    path: "webtrackings",
    loadComponent: () =>
      import("./component/webtracking-list/webtracking-list.component").then(
        (component) => component.WebtrackingListComponent
      ),
  },
  // {
  //   path: "crm/campaigns",
  //   loadComponent: () =>
  //     import("./component/campaign/campaign.component").then(
  //       (component) => component.CampaignComponent
  //     ),
  // },
  {
    path: "crm/campaigns/:id",
    loadComponent: () =>
      import("./component/campaign/campaign.component").then(
        (component) => component.CampaignComponent
      ),
  },
  {
    path: "crm/campaigns",
    loadComponent: () =>
      import("./component/campaigns-list/campaigns-list.component").then(
        (component) => component.CampaignsListComponent
      ),
  },

  // {
  //   path: "crm/customers",
  //   loadComponent: () =>
  //     import("./component/campaign/customer/customer.component").then(
  //       (component) => component.ContactComponent
  //     ),
  // },
  {
    path: "crm/customers",
    loadComponent: () =>
      import("./component/campaign/customer-list/customer-list.component").then(
        (component) => component.CustomerListComponent
      ),
  },


  {
    path: "crm/customers/:id",
    loadComponent: () =>
      import("./component/campaign/customer/customer.component").then(
        (component) => component.ContactComponent
      ),
  },
  {
    path: "crm/campaign-contacts/:id",
    loadComponent: () =>
      import("./component/campaign/campaign-contact/campaign-contact.component").then(
        (component) => component.CampaignContactComponent
      ),
  },
  // {
  //   path: "crm/message-templates",
  //   loadComponent: () =>
  //     import("./component/campaign/message-template/message-template.component").then(
  //       (component) => component.MessageTemplateComponent
  //     ),
  // },
  {
    path: "crm/message-templates/:id",
    loadComponent: () =>
      import("./component/campaign/message-template/message-template.component").then(
        (component) => component.MessageTemplateComponent
      ),
  },
  {
    path: "crm/message-templates",
    loadComponent: () =>
      import("./component/message-template-list/message-template-list.component").then(
        (component) => component.MessageTemplateListComponent
      ),
  },

  {
    path: "crm/campaign-settings",
    loadComponent: () =>
      import("./component/campaign/campaign-settings/campaign-settings.component").then(
        (component) => component.CampaignSettingsComponent
      ),
  },

  {
    path: "crm/bulk-contact",
    loadComponent: () =>
      import("./component/campaign/bulk-contact/bulk-contact.component").then(
        (component) => component.BulkContactComponent
      ),
  },
  {
    path: "crm/campaign-settings/:id",
    loadComponent: () =>
      import("./component/campaign/campaign-settings/campaign-settings.component").then(
        (component) => component.CampaignSettingsComponent
      ),
  },



  {
    path: "file-browser",
    loadComponent: () =>
      import("./component/file-browser/file-browser.component").then(
        (component) => component.FileBrowserComponent
      ),
  },
  {
    path: "account-app-config",
    loadComponent: () =>
      import("./component/admin/account-app-config/account-app-config.component").then(
        (component) => component.AccountAppConfigComponent
      ),
  },
  {
    path: "account-app-config/:id",
    loadComponent: () =>
      import("./component/admin/account-app-config/account-app-config.component").then(
        (component) => component.AccountAppConfigComponent
      ),
  },
  {
    path: "crm/wa-templates",
    loadComponent: () =>
      import("./component/campaign/wa-template/wa-template.component").then(
        (component) => component.WaTemplateComponent
      ),
  },
  // {
  //   path: "crm/launch-campaign",
  //   loadComponent: () =>
  //     import("./component/campaign/campaign.component").then(
  //       (component) => component.CampaignLaunchComponent
  //     ),
  // },
  {
    path: "crm/campaign/:id/launch",
    loadComponent: () =>
      import("./component/campaign/campaign.component").then(
        (component) => component.CampaignLaunchComponent
      ),
  },
  {
    path: "search",
    loadComponent: () =>
      import("./component/simple-search/simple-search.component").then(
        (component) => component.SimpleSearchComponent
      ),
  },

  // {
  //   path: "crm/products",
  //   loadComponent: () =>
  //     import("./component/products/products.component").then(
  //       (component) => component.ProductsComponent
  //     ),
  // },
  {
    path: "crm/products/:id",
    loadComponent: () =>
      import("./component/products/products.component").then(
        (component) => component.ProductsComponent
      ),
  },
  {
    path: "crm/products",
    loadComponent: () =>
      import("./component/product-list/product-list.component").then(
        (component) => component.ProductListComponent
      ),
  },
  // {
  //   path: "crm/orders",
  //   loadComponent: () =>
  //     import("./component/orders/orders.component").then(
  //       (component) => component.OrdersComponent
  //     ),
  // },
  {
    path: "crm/orders/:id",
    loadComponent: () =>
      import("./component/orders/orders.component").then(
        (component) => component.OrdersComponent
      ),
  },
  {
    path: "crm/orders",
    loadComponent: () =>
      import("./component/order-list/order-list.component").then(
        (component) => component.OrderListComponent
      ),
  },

  {
    path: "crm/address",
    loadComponent: () =>
      import("./component/address/address.component").then(
        (component) => component.AddressComponent
      ),
  },
  {
    path: "crm/address/:id",
    loadComponent: () =>
      import("./component/address/address.component").then(
        (component) => component.AddressComponent
      ),
  },

  // {
  //   path: "code-snippet",
  //   loadComponent: () =>
  //     import("./component/code-snippet/code-snippet.component").then(
  //       (component) => component.CodeSnippetComponent
  //     ),
  // },
  // {
  //   path: "code-snippet/:id",
  //   loadComponent: () =>
  //     import("./component/code-snippet/code-snippet.component").then(
  //       (component) => component.CodeSnippetComponent
  //     ),
  // },
  // {
  //   path: "webhooks",
  //   loadComponent: () =>
  //     import("./component/api-playground/api-playground.component").then(
  //       (component) => component.ApiPlaygroundComponent
  //     ),
  // },
  {
    path: "webhooks/:id",
    loadComponent: () =>
      import("./component/webhooks/webhooks.component").then(
        (component) => component.WebhooksComponent
      ),
  },
  {
    path: "comments",
    loadComponent: () =>
      import("./component/comments/comments.component").then(
        (component) => component.CommentsComponent
      ),
  },
  {
    path: "product-search",
    loadComponent: () =>
      import("./component/product-search/product-search.component").then(
        (component) => component.ProductSearchComponent
      ),
  },
  // {
  //   path: "product-details",
  //   loadComponent: () =>
  //     import("./component/product-detail/product-detail.component").then(
  //       (component) => component.ProductDetailComponent
  //     ),
  // },
  {
    path: "product-details/:id",
    loadComponent: () =>
      import("./component/product-detail/product-detail.component").then(
        (component) => component.ProductDetailComponent
      ),
  },

  {
    path: "ecommerce-homepage",
    loadComponent: () =>
      import("./component/ecommerce-homepage/ecommerce-homepage.component").then(
        (component) => component.EcommerceHomepageComponent
      ),
  },
  // {
  //   path: "rule-table",
  //   loadComponent: () =>
  //     import("./component/rule-table/rule-table.component").then(
  //       (component) => component.RuleTableComponent
  //     ),
  // },
  {
    path: "invoice-page/:id",
    loadComponent: () =>
      import("./component/invoice/invoice.component").then(
        (component) => component.InvoiceComponent
      ),
  },

  // {
  //   path: "crm/promotion",
  //   loadComponent: () =>
  //     import("./component/promotion/promotion.component").then(
  //       (component) => component.PromotionComponent
  //     ),
  // },
  {
    path: "crm/promotions/:id",
    loadComponent: () =>
      import("./component/promotion/promotion.component").then(
        (component) => component.PromotionComponent
      ),
  },
  {
    path: "crm/promotions",
    loadComponent: () =>
      import("./component/promotion-list/promotion-list.component").then(
        (component) => component.PromotionListComponent
      ),
  },
  {
    path: "crm",
    loadComponent: () =>
      import("./component/crm-homepage/crm-homepage.component").then(
        (component) => component.CRMHomepageComponent
      )
  },
  {
    path: "kg",
    loadComponent: () =>
      import("./component/knowledge-graph-homepage/knowledge-graph-homepage.component").then(
        (component) => component.KnowledgeGraphHomepageComponent
      )
  },
  {
    path: "cpe",
    loadComponent: () =>
      import("./component/cpe-homepage/cpe-homepage.component").then(
        (component) => component.CpeHomepageComponent
      )
  },
  {
    path: "firewall",
    loadComponent: () =>
      import("./component/firewall-homepage/firewall-homepage.component").then(
        (component) => component.FirewallHomepageComponent
      )
  },
  {
    path: "natural-lang",
    loadComponent: () =>
      import("./component/natural-lang-homepage/natural-lang-homepage.component").then(
        (component) => component.NaturalLangHomepageComponent
      )
  },

  {
    path: "datasource",
    loadComponent: () =>
      import("./component/datasource/datasource.component").then(
        (component) => component.DatasourceComponent
      )
  },

  {
    path: "register-connector",
    loadComponent: () =>
      import("./component/register-connector/register-connector.component").then(
        (component) => component.RegisterConnectorComponent
      )
  },
  {
    path: "rule-dashboard",
    loadComponent: () =>
      import("./component/rule-dashboard/rule-dashboard.component").then(
        (component) => component.RuleDashboardComponent
      )
  },
  {
    path: "rule-dashboard/:ruleId",
    loadComponent: () =>
      import("./component/rule-dashboard/rule-dashboard.component").then(
        (component) => component.RuleDashboardComponent
      )
  },
  {
    path: "color-picker",
    loadComponent: () =>
      import("./component/color-picker/color-picker.component").then(
        (component) => component.ColorPickerComponent
      )
  },
  {
    path: "mitre-attack-dashboard",
    loadComponent: () =>
      import("./component/Mitre/mitre-attack-dashboard/mitre-attack-dashboard.component").then(
        (component) => component.MitreAttackDashboardComponent
      )
  },
  {
    path: "alert-center",
    loadComponent: () =>
      import("./component/alert-center/alert-center.component").then(
        (component) => component.AlertCenterComponent
      )
  },
  {
    path: "alert-center/:id",
    loadComponent: () =>
      import("./component/alert-center/alert-center.component").then(
        (component) => component.AlertCenterComponent
      )
  },

  {
    path: "dashboards",
    loadComponent: () =>
      import("./component/default-dashboard/default-dashboard.component").then(
        (component) => component.DefaultDashboardComponent
      )
  },

  {
    path: "support-cases",
    loadComponent: () =>
      import("./component/support-cases/support-cases.component").then(
        (component) => component.MyCasesComponent
      )
  },
  {
    path: "users-search",
    loadComponent: () =>
      import("./component/global-search/global-search.component").then(
        (component) => component.GlobalSearchComponent
      )
  },
  {
    path: "account-search",
    loadComponent: () =>
      import("./component/global-accounts/global-accounts.component").then(
        (component) => component.GlobalAccountsComponent
      )
  },

  {
    path: "wizard",
    loadComponent: () =>
      import("./component/wizard/wizard.component").then(
        (component) => component.WizardComponent
      )
  },


  {
    path: "learning-app",
    loadComponent: () =>
      import("./quiz/quiz-home-page/quiz-home-page.component").then(
        (component) => component.QuizHomePageComponent
      )
  },
  {
    path: "quizzes/submission",
    loadComponent: () =>
      import("./quiz/quiz-submission/quiz-submission.component").then(
        (component) => component.QuizSubmissionComponent
      )
  },
  {
    path: "quizzes",
    canActivate: [routingAuthGuard],
    loadComponent: () =>
      import("./quiz/quiz-dashboard/quiz-dashboard.component").then(
        (component) => component.QuizDashboardComponent
      )
  },
  {
    path: "quiz-dashboard/:id",
    loadComponent: () =>
      import("./quiz/quiz-dashboard/quiz-dashboard.component").then(
        (component) => component.QuizDashboardComponent
      )
  },
  {
    path: "quizzes/:id",
    loadComponent: () =>
      import("./quiz/quiz-details/quiz-details.component").then(
        (component) => component.QuizDetailsComponent
      )
  },
  {
    path: "quizzes/:quizId/questions/:questionId",
    loadComponent: () =>
      import("./quiz/quiz-question/quiz-question.component").then(
        (component) => component.QuizQuestionComponent
      )
  },
  {
    path: "quiz-settings",
    loadComponent: () =>
      import("./quiz/quiz-settings/quiz-settings.component").then(
        (component) => component.QuizSettingsComponent
      )
  },
  {
    path: "default-home",
    loadComponent: () =>
      import("./component/default-homepage/default-homepage.component").then(
        (component) => component.DefaultHomepageComponent
      )
  },
  {
    path: "upload-quiz",
    loadComponent: () =>
      import("./quiz/quiz-upload-question/quiz-upload-question.component").then(
        (component) => component.QuizUploadQuestionComponent
      )
  },
  {
    path: "payments/:id",
    loadComponent: () =>
      import("./component/payments/payments.component").then(
        (component) => component.PaymentsComponent
      )
  },
  {
    path: "payments",
    loadComponent: () =>
      import("./component/payments/payments.component").then(
        (component) => component.PaymentsComponent
      )
  },
  {
    path: "crm/payment-reports",
    loadComponent: () =>
      import("./component/payment-report/payment-report.component").then(
        (component) => component.PaymentReportComponent
      )
  },

  {
    path: "select-plans",
    loadComponent: () =>
      import("./component/select-application/select-application.component").then(
        (component) => component.SelectApplicationComponent
      )
  },
  {
    path: "select-plans/:id",
    loadComponent: () =>
      import("./component/select-application/select-application.component").then(
        (component) => component.SelectApplicationComponent
      )
  },
  {
    path: "invoice/:id",
    loadComponent: () =>
      import("./component/order-invoice/order-invoice.component").then(
        (component) => component.OrderInvoiceComponent
      )
  },
  {
    path: "complete-payment/:orderId",
    loadComponent: () =>
      import("./component/complete-payment/complete-payment.component").then(
        (component) => component.CompletePaymentComponent
      )
  },


  {
    path: "workflows/feeds",
    loadComponent: () =>
      import("./workflow/workflow-feeds/workflow-feeds.component").then(
        (component) => component.WorkflowFeedsComponent
      )
  },
  {
    path: "workflows/invocations",
    loadComponent: () =>
      import("./workflow/workflow-invocation/workflow-invocation.component").then(
        (component) => component.WorkflowInvocationComponent
      )
  },
  {
    path: "workflows/samples",
    loadComponent: () =>
      import("./workflow/workflow-sample/workflow-sample.component").then(
        (component) => component.WorkflowSampleComponent
      )
  },
  {
    path: "workflows/samples/:id",
    loadComponent: () =>
      import("./workflow/workflow-sample-details/workflow-sample-details.component").then(
        (component) => component.WorkflowSampleDetailsComponent
      )
  },

  {
    path: "workflows/invocations/:id",
    loadComponent: () =>
      import("./workflow/workflow-invocation-details/workflow-invocation-details.component").then(
        (component) => component.WorkflowInvocationDetailsComponent
      )
  },
  // {
  //   path: "workflows/invocations/details",
  //   loadComponent: () =>
  //     import("./workflow/workflow-invocation-details/workflow-invocation-details.component").then(
  //       (component) => component.WorkflowInvocationDetailsComponent
  //     )
  // },

  {
    path: "workflows/dashboard",
    loadComponent: () =>
      import("./workflow/workflow-dashboard/workflow-dashboard.component").then(
        (component) => component.WorkflowDashboardComponent
      )
  },
  {
    path: "workflows-tasklogs",
    loadComponent: () =>
      import("./workflow/workflow-task-logs/workflow-task-logs.component").then(
        (component) => component.WorkflowTaskLogsComponent
      )
  },
  {
    path: "workflows",
    loadComponent: () =>
      import("./workflow/workflow-list/workflow-list.component").then(
        (component) => component.WorkflowListComponent
      )
  },
  {
    path: "workflows/:id",
    loadComponent: () =>
      import("./workflow/workflow-details/workflow-details.component").then(
        (component) => component.WorkflowDetailsComponent
      )
  },
  {
    path: "workflows/:id/tasks",
    loadComponent: () =>
      import("./workflow/workflow-details-by-gantt/workflow-details-by-gantt.component").then(
        (component) => component.WorkflowDetailsByGanttComponent
      )
  },

  {
    path: "workflow-logs/:id",
    loadComponent: () =>
      import("./workflow/workflow-task-log-details/workflow-task-log-details.component").then(
        (component) => component.WorkflowTaskLogDetailsComponent
      )
  },
  // {
  //   path: "workflows-simple",
  //   loadComponent: () =>
  //     import("./workflow/workflow-simple/workflow-simple.component").then(
  //       (component) => component.WorkflowSimpleComponent
  //     )
  // },
  {
    path: "workflows/:id/designer",
    loadComponent: () =>
      import("./workflow/workflow-designer/workflow-designer.component").then(
        (component) => component.WorkflowDesignerComponent
      )
  },
  {
    path: "workflows/:id/invoke",
    loadComponent: () =>
      import("./workflow/workflow-invoke/workflow-invoke.component").then(
        (component) => component.WorkflowInvokeComponent
      )
  },


  {
    path: "workflow-app",
    loadComponent: () =>
      import("./workflow/workflow-home-page/workflow-home-page.component").then(
        (component) => component.WorkflowHomePageComponent
      )
  },


  {
    path: "workflows/:id/gantt",
    loadComponent: () =>
      import("./workflow/workflow-gantt-chart/workflow-gantt-chart.component").then(
        (component) => component.WorkflowGanttChartComponent
      )
  },

  {
    path: "workflow-tasks/:id",
    loadComponent: () =>
      import("./workflow/workflow-task-details/workflow-task-details.component").then(
        (component) => component.WorkflowTaskDetailsComponent
      )
  },

  {
    path: "workflow-tasks",
    loadComponent: () =>
      import("./workflow/workflow-task-details/workflow-task-details.component").then(
        (component) => component.WorkflowTaskDetailsComponent
      )
  },
  {
    path: "workflow-settings",
    loadComponent: () =>
      import("./workflow/workflow-settings/workflow-settings.component").then(
        (component) => component.WorkflowSettingsComponent
      )
  },
  {
    path: "workflow-reports",
    loadComponent: () =>
      import("./workflow/workflow-reports/workflow-reports.component").then(
        (component) => component.WorkflowReportsComponent
      )
  },


  {
    path: "password-policy",
    loadComponent: () =>
      import("./component/password-policy/password-policy.component").then(
        (component) => component.PasswordPolicyComponent
      )
  },
  {
    path: "tree-user-group",
    loadComponent: () =>
      import("./component/tree-user-group/tree-user-group.component").then(
        (component) => component.TreeUserGroupComponent
      )
  },

  {
    path: "learning",
    loadComponent: () =>
      import("./knowledge/knowledge-search/knowledge-search.component").then(
        (component) => component.KnowledgeSearchComponent
      )
  },

  {
    path: "learning/:id/attempt",
    loadComponent: () =>
      import("./knowledge/knowledge-check/knowledge-check.component").then(
        (component) => component.KnowledgeChekComponent
      )
  },
  {
    path: "learning/:id",
    loadComponent: () =>
      import("./knowledge/knowledge-details/knowledge-details.component").then(
        (component) => component.KnowledgeDetailsComponent
      )
  },

  {
    path: "knowledge-details",
    loadComponent: () =>
      import("./knowledge/knowledge-details/knowledge-details.component").then(
        (component) => component.KnowledgeDetailsComponent
      )
  },
  {
    path: "learning-home",
    loadComponent: () =>
      import("./knowledge/knowledge-home/knowledge-home.component").then(
        (component) => component.KnowledgeHomeComponent
      )
  },

  {
    path: "teams",
    loadComponent: () =>
      import("./quiz/team/team-list/team-list.component").then(
        (component) => component.TeamListComponent
      )
  },
  {
    path: "teams/:id",
    loadComponent: () =>
      import("./quiz/team/team-details/team-details.component").then(
        (component) => component.TeamDetailsComponent
      )
  },
  {
    path: "teams-report",
    loadComponent: () =>
      import("./quiz/team/team-report/team-report.component").then(
        (component) => component.TeamReportComponent
      )
  },
  {
    path: "quiz-feedback",
    loadComponent: () =>
      import("./quiz/quiz-feedback/quiz-feedback.component").then(
        (component) => component.QuizFeedbackComponent
      )
  },

  {
    path: "**",
    loadComponent: () =>
      import("./component/bad-request/bad-request.component").then(
        (component) => component.BadRequestComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
